<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}中医</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="姓名" prop="name" class="w50 mr16">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="性别" prop="genderDictionaryItemId" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.genderDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelet($event, 'genderDictionaryItemId')"
				>
					<el-option v-for="(item, index) in sexDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="生日" prop="birthDate" class="w50 mr16">
				<el-date-picker value-format="yyyy-MM-dd" v-model.trim="ruleForm.birthDate" :disabled="isShowDetail" type="date" placeholder="选择日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="电话" prop="phoneNumber" class="w50 mr0">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="身份证" prop="identificationCode">
				<el-input v-model.trim="ruleForm.identificationCode" maxlength="18" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="执业单位" prop="medicalOrganizationId" class="w50 mr16">
				<el-select :disabled="isShowDetail" v-model="ruleForm.medicalOrganizationId" placeholder="请选择" clearable @change="changeMedical">
					<el-option
						v-for="(item, index) in organizeDictionaryItemList"
						:key="index"
						:disabled="!item.enabled"
						:label="item.name"
						:value="item.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="medicalDepartmentId" label="科室" class="w50 mr0">
				<el-select
					:disabled="isShowDetail || !ruleForm.medicalOrganizationId"
					v-model="ruleForm.medicalDepartmentId"
					placeholder="请选择"
					@change="changeSelet($event, 'medicalDepartmentId')"
					clearable
				>
					<el-option v-for="(item, index) in departmentList" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="practiceCategoryDictionaryItemId" label="执业类别" class="w50 mr16">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.practiceCategoryDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelet($event, 'practiceCategoryDictionaryItemId')"
				>
					<el-option v-for="(item, index) in typeDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="positionDictionaryItemId" label="岗位" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.positionDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelet($event, 'positionDictionaryItemId')"
				>
					<el-option v-for="(item, index) in postList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="professionalTitleDictionaryItemId" label="职称" class="w50 mr16">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.professionalTitleDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelet($event, 'professionalTitleDictionaryItemId')"
				>
					<el-option v-for="(item, index) in titleList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="businessTypeDictionaryItemId" label="业务性质" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.businessTypeDictionaryItemId"
					placeholder="请选择"
					clearable
					@change="changeSelet($event, 'businessTypeDictionaryItemId')"
				>
					<el-option v-for="(item, index) in buessnissList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="医师介绍" prop="description">
				<el-input type="textarea" :disabled="isShowDetail" v-model.trim="ruleForm.description" autosize></el-input>
			</el-form-item>
			<el-form-item label="擅长" prop="advantage">
				<el-input type="textarea" :disabled="isShowDetail" v-model.trim="ruleForm.advantage" autosize></el-input>
			</el-form-item>
			<el-form-item prop="certificateImg" label="上传医师资格证" class="w50 mr16">
				<Upload
					ref="certificateImage"
					v-model="ruleForm.certificateImg"
					modulePath="MEDICAL_PRACTICING_CERTIFICATE"
					@uploadImg="uploadCertificateImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
			<el-form-item prop="practicingImg" label="上传医师执业证" class="w50 mr0">
				<Upload
					ref="practicingImage"
					v-model="ruleForm.practicingImg"
					modulePath="MEDICAL_PRACTICING_CERTIFICATE"
					@uploadImg="uploadPracticingImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
			<el-form-item label="上传医师照片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="MEDICAL_PRACTICING_CERTIFICATE"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { verifyPhone, verifyIdCard } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		var checkIdCard = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入身份证号'));
			} else if (!verifyIdCard(value)) {
				callback(new Error('请输入正确的身份证号'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				medicalDepartmentId: null, //科室
				positionDictionaryItemId: null, //岗位
				professionalTitleDictionaryItemId: null, //职称
				businessTypeDictionaryItemId: null, //业务性质
				description: '',
				phoneNumber: '',
				identificationCode: '',
				doctorQualification: '',
				medicalOrganizationId: '',
				practiceCategoryDictionaryItemId: '',
				birthDate: '',
				genderDictionaryItemId: '',
				certificateImg: [],
				practicingImg: [],
				images: [],
				positionCertification: '',
				advantage: '',
			},
			sexDictionaryItemList: [],
			organizeDictionaryItemList: [],
			typeDictionaryItemList: [],
			departmentList: [],
			postList: [],
			titleList: [],
			buessnissList: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				identificationCode: [{ required: true, validator: checkIdCard, trigger: 'blur' }],
				doctorQualification: [{ required: true, message: '请输入', trigger: 'blur' }],
				medicalDepartmentId: [{ required: true, message: '请选择', trigger: 'blur' }],
				positionDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				professionalTitleDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				businessTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				medicalOrganizationId: [{ required: true, message: '请选择', trigger: 'blur' }],
				practiceCategoryDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				birthDate: [{ required: true, message: '请选择', trigger: 'blur' }],
				genderDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				// certificateImg: [{ required: true, message: '上传医师资格证', trigger: 'change' }],
				// practicingImg: [{ required: true, message: '请上传医师执业证', trigger: 'change' }],
				// images: [{ required: true, message: '请上传图片', trigger: 'change' }],
				positionCertification: [{ required: true, message: '请输入', trigger: 'blur' }],
				advantage: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			attachmentInfo: [],
			saveLoading: false,
			link: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init({ row, link }) {
			this.createdVisible = true;
			this.getAttachmentGroups();
			this.getSexList();
			this.getOrganizeList();
			this.getType();
			this.getDepartment();
			this.getTitleList();
			this.getBuessnissList();
			this.link = link;
			if (row) {
				this.setRuleForm(row);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},

		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.genderDictionaryItemId = data.genderDictionaryItem?.id || null;
			this.ruleForm.medicalOrganizationId = data.medicalOrganization?.id || null;
			this.ruleForm.practiceCategoryDictionaryItemId = data.practiceCategoryDictionaryItem?.id || null;
			this.ruleForm.medicalDepartmentId = data.medicalDepartment?.id || null;
			this.ruleForm.positionDictionaryItemId = data.positionDictionaryItem?.id || null;
			this.ruleForm.professionalTitleDictionaryItemId = data.professionalTitleDictionaryItem?.id || null;
			// this.ruleForm.businessTypeDictionaryItemId = data.businessTypeDictionaryItem?.id || null;
			this.$set(this.ruleForm, 'businessTypeDictionaryItemId', data.businessTypeDictionaryItem?.id || null);
			this.ruleForm.images =
				(data.attachmentDtoMap &&
					data.attachmentDtoMap['COVER'] &&
					data.attachmentDtoMap['COVER'].map((item) => {
						return {
							url: item.link,
							uid: item.id,
							title: item.title,
						};
					})) ||
				[];
			this.ruleForm.practicingImg =
				(data.attachmentDtoMap &&
					data.attachmentDtoMap['PRACTICING'] &&
					data.attachmentDtoMap['PRACTICING'].map((item) => {
						return {
							url: item.link,
							uid: item.id,
							title: item.title,
						};
					})) ||
				[];
			this.ruleForm.certificateImg =
				(data.attachmentDtoMap &&
					data.attachmentDtoMap['QUALIFICATION'] &&
					data.attachmentDtoMap['QUALIFICATION'].map((item) => {
						return {
							url: item.link,
							uid: item.id,
							title: item.title,
						};
					})) ||
				[];
			this.$forceUpdate();
		},
		// 获取性别下拉
		getSexList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'GENDER' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.sexDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取执业单位下拉
		getOrganizeList() {
			this.$http
				.get(this.api['MedicalOrganizations#index'].href, { params: { current: 1, size: 9999, discriminationCode: 'O' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.organizeDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取执业列表下拉
		getType() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'PRACTICE_CATEGORY', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.typeDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取业务性质列表下拉
		getBuessnissList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'BUSINESS_NATURE_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.buessnissList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取岗位列表下拉
		getDepartment() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'POST', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.postList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取职称列表下拉
		getTitleList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'POSITIONAL_TITLE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.titleList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeMedical(val) {
			this.$set(this.ruleForm, 'medicalDepartmentId', null);
			this.departmentList = [];
			this.getDepartmentList(val);
		},
		changeSelet(val, type) {
			this.ruleForm[type] = val;
			this.$forceUpdate();
		},
		// 获取科室列表下拉
		getDepartmentList(id) {
			let data = {
				current: 1,
				size: 9999,
				medicalOrganizationId: id || null,
				enabled: true,
			};
			this.$http
				.get(this.api['MedicalOrganizationDepartments#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.departmentList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachmentGroups() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'DOCTOR_AND_NURSE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.certificateImg = [];
			this.ruleForm.practicingImg = [];
			this.ruleForm.images = [];
			this.$refs.certificateImage.images = [];
			this.$refs.practicingImage.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let qualificationImg = this.ruleForm.certificateImg.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'QUALIFICATION').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'QUALIFICATION').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let practicingImg = this.ruleForm.practicingImg.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'PRACTICING').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'PRACTICING').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let images = this.ruleForm.images.map((item) => {
						return {
							link: item.url,
							subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'COVER').subjectTypeCode,
							title: item.title,
							attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'COVER').id,
							mimeType: item.title.split('.')[1],
						};
					});
					let params = {
						...this.ruleForm,
						attachments: [...qualificationImg, ...practicingImg, ...images],
					};
					console.log(params);
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi;
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
		uploadCertificateImg(dataImg) {
			this.ruleForm.certificateImg = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
		uploadPracticingImg(dataImg) {
			this.ruleForm.practicingImg = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.el-textarea__inner {
		min-height: 64px !important;
		line-height: 18px !important;
		padding: 5px 12px !important;
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
</style>